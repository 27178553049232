* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

tbody > tr:last-child > td {
  border-bottom: 1px solid #eeeeee;
}
